import React from "react"

import { Subscribe } from "unstated"
import RockPointsStore from "../../stores/rockpoints"
import authStore from "../../stores/auth"

import RockPointsApp from "./components/_RockPoints"
import Layout from "./components/_Layout"

function RockPoints() {
  return (
    <Layout name="RockPoints" title="Manage your RockPoints">
      <Subscribe to={[RockPointsStore]}>
        {rpStore => (
          <RockPointsApp
            {...{
              rpStore,
              authStore,
            }}
          />
        )}
      </Subscribe>
    </Layout>
  )
}

export default RockPoints
